@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  column-gap: 12px;
}

.button {
  flex: 1;
  min-height: 45px;
  height: 45px;
}

.buyNow, .buyNow:hover, .buyNow:focus {
  background-color: var(--marketplaceColor);
}

.notes {
  margin-top: 8px;
  text-align: center;
  composes: marketplaceSmallFontStyles from global;
}