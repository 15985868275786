@import '../../../styles/customMediaQueries.css';

.root {}

.sectionHeading {
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
}

.notes {
  composes: marketplaceSmallFontStyles from global;
  margin: 8px 0 0 0;
  color: var(--colorGrey500);
}