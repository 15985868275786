@import '../../../styles/customMediaQueries.css';

.root {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionHeading {
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.detailsGrid {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
}

.detailLabel {
  composes: p from global;
  font-weight: var(--fontWeightBold);
  margin: 0;
  color: var(--colorGrey500);
}

.detailValue {
  composes: p from global;
  margin: 0;
  color: var(--colorGrey500);
  font-weight: var(--fontWeightBold);
}