@import '../../../styles/customMediaQueries.css';

.root {
  display: inline-flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
}

.likes {
  composes: marketplaceSmallFontStyles from global;
}

.iconHeart {
  cursor: pointer;
}