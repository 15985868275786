@import '../../../styles/customMediaQueries.css';

.root {
  background-color: var(--colorGrey100);
  border-radius: 20px;
  border: solid 1px var(--colorBlack);
  padding: 8px 16px;
}

.sectionHeading {
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.text {
  composes: marketplaceSmallFontStyles from global;
  line-height: 16px;
}